import { AggregatorDoctor, AggregatorSpeciality, CheckCouponValidityQueryResult, CouponType } from '@/types-aggregatore';
import { sortArrayFromKey } from '@/utils/array';
import { capitalizeFirstCharInString, normalizeString } from './string';
import { AutocompleteSpecialitiesItemOption, DoctorOption } from '@/app/_components/specialitiesAutocomplete/types';
import { formatPrice } from './checkout';
import { areSameDayTimestamps } from './datetime';
import { DEFAULT_DOC_ALIAS } from '@/costants';
import { ReservationOrigin } from '@/types-core';

/**
 * Transform an array of Specialization objects into an array of SpecialitiesAutocompleteItemOption objects, which can be used to populate an autocomplete component.
 * The function filters out any Specialization objects that do not have a service property and formats the price property of the remaining objects.
 *
 * @param items Optional array of Specialization objects.
 * @param clinicDomiciliary Optional boolean value that determines whether the AutocompleteItemOption objects should have a domiciliary property.
 * @returns Array of AutocompleteItemOption objects sorted by their value property.
 */
export const makeAutocompleteItems = (items?: AggregatorSpeciality[], clinicDomiciliary = false): AutocompleteSpecialitiesItemOption[] => {
  if (!items) return [];
  const groupedSpecialities = groupSpecialities(items);

  const autocompleteItems: AutocompleteSpecialitiesItemOption[] = [];

  for (const speciality of groupedSpecialities) {
    if (
      speciality.specialities.length === 1 &&
      speciality.specialities[0].doctors?.length === 1 &&
      !speciality.specialities[0].doctors[0]?.isNameVisible
    ) {
      const doc = speciality.specialities.at(0)?.doctors?.at(0);
      const spec = speciality.specialities.at(0);
      if (doc && spec)
        autocompleteItems.push({
          isSectionTitle: true,
          service: speciality.groupedService,
          doctor: mapDoctor(clinicDomiciliary, spec, doc),
        });
    } else {
      autocompleteItems.push({
        isSectionTitle: true,
        service: speciality.groupedService,
      });
      for (const spec of speciality.specialities) {
        for (const doc of spec.doctors ?? []) {
          if (doc)
            autocompleteItems.push({
              isSectionTitle: false,
              service: spec.label,
              doctor: mapDoctor(clinicDomiciliary, spec, doc),
            });
        }
      }
    }
  }
  return autocompleteItems;
};

/**
 * Filter an array of AutocompleteItemOption objects based on a given input.
 *
 * @param autocompleteItems Array of AutocompleteItemOption objects.
 * @param autocompleteInput String representing the input to filter by.
 * @returns A filtered array of AutocompleteItemOption objects.
 */
export const filterAutocompleteItemsBySpeciality = (autocompleteItems: AutocompleteSpecialitiesItemOption[], autocompleteInput: string) => {
  const validServiceIndexes: number[] = [];
  const validDoctorIndexes: number[] = [];
  const validDoctorServices: string[] = [];

  autocompleteItems.forEach((item, index) => {
    // Checking services that match the input
    if (normalizeString(item.service).includes(normalizeString(autocompleteInput))) {
      validServiceIndexes.push(index);
    }

    // Checking doctors that match the input
    if (normalizeString((item.doctor?.name ?? '') + (item.doctor?.surname ?? '')).includes(normalizeString(autocompleteInput))) {
      validDoctorIndexes.push(index);
      validDoctorServices.push(normalizeString(item.service));
    }
  });

  if (validDoctorIndexes.length === 0) {
    if (validServiceIndexes.length === 0) return [];
    return validServiceIndexes.map(specialityIndex => autocompleteItems[specialityIndex]);
  }

  // If you only have matched the doctors info, you also have to recover the speciality title, hence the following recollection
  autocompleteItems.forEach((item, index) => {
    if (item.isSectionTitle && validDoctorServices.includes(normalizeString(item.service))) {
      validServiceIndexes.push(index);
    }
  });

  // Removing duplicates and sorting
  return [...new Set([...validServiceIndexes, ...validDoctorIndexes].sort())].map(index => autocompleteItems[index]);
};

export const getReservationOriginByCoupon = (
  coupon: CheckCouponValidityQueryResult['checkCouponValidity'],
  baseReservationOrigin: ReservationOrigin,
) => {
  if (coupon.type === CouponType.Sisalute) {
    return ReservationOrigin.Sisalute;
  }
  if (coupon.type === CouponType.Argento) {
    return ReservationOrigin.Argento;
  }
  if (coupon.type === CouponType.Morato) {
    return ReservationOrigin.Morato;
  }
  if (coupon.type === CouponType.Acuore) {
    return ReservationOrigin.Acuore;
  }
  if (coupon.type === CouponType.Cassalombarda) {
    return ReservationOrigin.Cassalombarda;
  }
  return baseReservationOrigin;
};

const groupSpecialities = (items: AggregatorSpeciality[]) => {
  const specialityGroupedByName: { [key: string]: { groupedService: string; specialities: AggregatorSpeciality[] } } = {};

  for (const item of items ?? []) {
    if (!(item?.label && item?.id && item.doctors)) continue;

    const service = capitalizeFirstCharInString(item.label);

    if (!specialityGroupedByName[service]) {
      specialityGroupedByName[service] = {
        groupedService: service,
        specialities: [],
      };
    }

    specialityGroupedByName[service].specialities.push(item);
  }

  const groupedSpecialities = Object.values(specialityGroupedByName);

  return sortArrayFromKey(groupedSpecialities, 'groupedService');
};
const mapDoctor = (clinicDomiciliary: boolean, spec: AggregatorSpeciality, doc: AggregatorDoctor): DoctorOption => {
  return {
    domiciliary: clinicDomiciliary,
    channels: spec?.specialityChannels ?? [],
    id: doc?.id,
    name: doc?.isNameVisible ? doc?.name : DEFAULT_DOC_ALIAS,
    price: spec?.price ? formatPrice({ amount: spec?.price }) : '',
    hasDynamicPrice: spec.hasDynamicPrice,
    serviceId: spec?.id ?? '',
    surname: doc?.isNameVisible ? doc?.surname : '',
    macrospeciality: spec.macroSpeciality ?? undefined,
    nextAvailability: doc?.nextAvailability ?? undefined,
  };
};

export const isTimeStampInSameDayOfLastAvailability = (selectedTimestamp: number, availabilitySlotsTimestamps: number[]) => {
  if (!availabilitySlotsTimestamps?.length) return false;
  const [lastTimestamp] = availabilitySlotsTimestamps.slice(-1);
  return areSameDayTimestamps(lastTimestamp, selectedTimestamp);
};

export const getDoctorName = (workgroupDoctors?: Array<AggregatorDoctor>, slotDoctorId?: string) => {
  if (!workgroupDoctors || !slotDoctorId) return DEFAULT_DOC_ALIAS;
  const doctor = workgroupDoctors.find(d => d.id === slotDoctorId);
  return doctor?.isNameVisible ? `${doctor.name} ${doctor.surname}` : DEFAULT_DOC_ALIAS;
};
